import React from "react";
import { graphql } from "gatsby";

import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/SEO";
import TaggedTextWithDescription from "../components/TaggedTextWithDescription";

import designIcon from "../../assets/images/design.svg";
import business_modelIcon from "../../assets/images/business.svg";
import developmentIcon from "../../assets/images/development.svg";
import architectureIcon from "../../assets/images/architecture.svg";
import commitmentIcon from "../../assets/images/commitment.svg";
import flexibilityIcon from "../../assets/images/flexibility.svg";
import timesavingIcon from "../../assets/images/timesaving.svg";
import scalabilityIcon from "../../assets/images/scalability.svg";

import ContactForm from "../components/ContactForm";
import HowWeLinkThings from "../components/HowWeLikeThings";
import Profiles from "../components/Profiles";

export const pageQuery = graphql`
  query ServiceQuery($uid: String!) {
    prismicService(uid: { eq: $uid }) {
      uid
      data {
        description
        title
        canonical_url {
          url
        }
        image1 {
          url
        }
        cover {
          alt
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 90
                tracedSVGOptions: { background: "#1e22aa", color: "#333" }
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
        service_image {
          alt
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 90
                tracedSVGOptions: { background: "#1e22aa", color: "#333" }
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
        content {
          html
        }
        tag_title
        tag_description
        tag_content
        how_we_like_things_done
        hire_process
        profiles {
          price
          skills
          title1
        }
        business_model {
          item
        }
        architecture {
          item
        }
        design {
          item
        }
        development {
          item
        }
        flexibility {
          item
        }
        scalability {
          item
        }
        commitment {
          item
        }
      }
    }
  }
`;
const Service = (props: any) => {
  const serviceObject = props.data.prismicService || {};
  const coverImage =
    props.data.prismicService.data.cover.localFile.childImageSharp
      .gatsbyImageData;
  // const serviceImage =
  //   props.data.prismicService.data.service_image.localFile.childImageSharp
  //     .fluid;

  const bulletLeft = (props.data.prismicService.data.generic1 || []).filter(
    ({ item }) => item?.length > 0
  );
  const bulletRight = (props.data.prismicService.data.generic2 || []).filter(
    ({ item }) => item?.length > 0
  );
  const architecture = (
    props.data.prismicService.data.architecture || []
  ).filter(({ item }) => item?.length > 0);
  const business_model = (
    props.data.prismicService.data.business_model || []
  ).filter(({ item }) => item?.length > 0);
  const design = (props.data.prismicService.data.design || []).filter(
    ({ item }) => item?.length > 0
  );
  const development = (props.data.prismicService.data.development || []).filter(
    ({ item }) => item?.length > 0
  );

  const commitment = (props.data.prismicService.data.commitment || []).filter(
    ({ item }) => item?.length > 0
  );
  const flexibility = (props.data.prismicService.data.flexibility || []).filter(
    ({ item }) => item?.length > 0
  );
  const timesaving = (props.data.prismicService.data.timesaving || []).filter(
    ({ item }) => item?.length > 0
  );
  const scalability = (props.data.prismicService.data.scalability || []).filter(
    ({ item }) => item?.length > 0
  );

  return (
    <>
      <SEO {...props.data.prismicService.data} />
      <div className="hero-service">
        <div className="service-intro-wrapp">
          {coverImage != "undefined" ? (
            <GatsbyImage
              image={coverImage}
              alt={serviceObject.data.cover.alt}
              className="service-background-image"
            />
          ) : (
            ""
          )}
          <div className="yellow-block-container">
            <div className="service-card position-absolute">
              <div className="service-card-container">
                <div className="wrapper-partner">
                  <div className="cap" style={{ marginTop: "5%" }}>
                    {"// Services"}
                  </div>
                  <h1 className="title-single-service blog-color-primary">
                    {serviceObject.data.title || ""}
                  </h1>
                  <p className="description-service blog-color-primary">
                    {serviceObject.data.description || ""}
                  </p>
                </div>
                <div className="service-block-yellow single-service-height" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="single-service-container">
        <div className="single-service-col service__image-column-container">
          <div className="single-service-content">
            <TaggedTextWithDescription
              subtitle={serviceObject.data.tag_title || ""}
              title={serviceObject.data.tag_description || ""}
              titleClass="full values-max"
              body={serviceObject.data.tag_content || ""}
              bodyClass="p2 full values-max"
            />
          </div>
        </div>
        <div className="single-service-col services__content-column-container">
          {bulletLeft?.length > 0 || bulletRight?.length > 0 ? (
            <div className="services__generic-row">
              <ul className="services__ul-left">
                {bulletLeft.map(({ item }, index) => (
                  <li key={`bullet-point-left-${index}`}>{item}</li>
                ))}
              </ul>
              <ul className="services__ul-right">
                {bulletRight.map(({ item }, index) => (
                  <li key={`bullet-point-right-${index}`}>{item}</li>
                ))}
              </ul>
            </div>
          ) : null}
          {design?.length > 0 ||
          architecture?.length > 0 ||
          development?.length > 0 ||
          business_model?.length > 0 ? (
            <div className="services__expertises-row">
              {business_model?.length > 0 ? (
                <div className="services__expertise-item item__88">
                  <div className="services-item-margin">
                    <img
                      alt="Business models"
                      src={business_modelIcon}
                      className="services__experise-icon"
                    />
                    <h2 className="heading-3 medium">Business Models</h2>
                    <ul className="services__expertises-list">
                      {business_model.map(({ item }, index) => (
                        <p key={`bullet-point-business-${index}`}>{item}</p>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}
              {architecture?.length > 0 ? (
                <div className="services__expertise-item">
                  <div className="services-item-margin">
                    <img
                      alt="Architecture"
                      src={architectureIcon}
                      className="services__experise-icon"
                    />
                    <h2 className="heading-3 medium">Architecture</h2>
                    <ul className="services__expertises-list">
                      {architecture.map(({ item }, index) => (
                        <p key={`bullet-point-arch-${index}`}>{item}</p>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}
              {design?.length > 0 ? (
                <div className="services__expertise-item item__88">
                  <div className="services-item-margin">
                    <img
                      alt="Design"
                      src={designIcon}
                      className="services__experise-icon"
                    />
                    <h2 className="heading-3 medium">Design</h2>
                    <ul className="services__expertises-list">
                      {design.map(({ item }, index) => (
                        <p key={`bullet-point-design-${index}`}>{item}</p>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}
              {development?.length > 0 ? (
                <div className="services__expertise-item">
                  <div className="services-item-margin">
                    <img
                      alt="Development"
                      src={developmentIcon}
                      className="services__experise-icon"
                    />
                    <h2 className="heading-3 medium">Expertise</h2>
                    <ul className="services__expertises-list">
                      {development.map(({ item }, index) => (
                        <p key={`bullet-point-dev-${index}`}>{item}</p>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}
              {scalability?.length > 0 ? (
                <div className="services__expertise-item item__88">
                  <div className="services-item-margin">
                    <img
                      alt="scalability"
                      src={scalabilityIcon}
                      className="services__experise-icon"
                    />
                    <h2 className="heading-3 medium">Scalability</h2>
                    <ul className="services__expertises-list no-bullet">
                      {scalability.map(({ item }, index) => (
                        <p key={`bullet-point-design-${index}`}>{item}</p>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}
              {commitment?.length > 0 ? (
                <div className="services__expertise-item">
                  <div className="services-item-margin">
                    <img
                      alt="Commitment"
                      src={commitmentIcon}
                      className="services__experise-icon"
                    />
                    <h2 className="heading-3 medium">Commitment</h2>
                    <ul className="services__expertises-list no-bullet">
                      {commitment.map(({ item }, index) => (
                        <p key={`bullet-point-design-${index}`}>{item}</p>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}

              {flexibility?.length > 0 ? (
                <div className="services__expertise-item">
                  <div className="services-item-margin">
                    <img
                      alt="flexibility"
                      src={flexibilityIcon}
                      className="services__experise-icon"
                    />
                    <h2 className="heading-3 medium">Flexibility</h2>
                    <ul className="services__expertises-list no-bullet">
                      {flexibility.map(({ item }, index) => (
                        <p key={`bullet-point-design-${index}`}>{item}</p>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}
              {timesaving?.length > 0 ? (
                <div className="services__expertise-item">
                  <div className="services-item-margin">
                    <img
                      alt="timesaving"
                      src={timesavingIcon}
                      className="services__experise-icon"
                    />
                    <h2 className="heading-3 medium">Time Saving</h2>
                    <ul className="services__expertises-list no-bullet">
                      {timesaving.map(({ item }, index) => (
                        <p key={`bullet-point-design-${index}`}>{item}</p>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      {serviceObject.data.how_we_like_things_done ? <HowWeLinkThings /> : null}
      {serviceObject.data.hire_process ? (
        <Profiles source={serviceObject.data.profiles} />
      ) : null}
      <div className="application-text1 service__contact-form" />
      <ContactForm TEMPLATE_ID="Website_Lead" type="service" />
    </>
  );
};

export default Service;
